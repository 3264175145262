import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import NAV_DATA, {
  InitNavDataEventPayload,
} from '@zola-helpers/client/dist/es/constants/navConstants';

const {
  EVENTS: { INIT_NAV_DATA },
} = NAV_DATA;

export const emitUpdateNavDataEvent = ({
  userContext,
  secondaryData,
  activePrimaryLink,
  activeSecondaryLink,
  disablePrimaryNavCollapse,
  disablePromoNavCollapse,
  emitDirectionEvents,
}: Partial<InitNavDataEventPayload>): void => {
  window.dispatchEvent(
    new CustomEvent<Partial<InitNavDataEventPayload>>(INIT_NAV_DATA, {
      detail: {
        userContext,
        secondaryData,
        activePrimaryLink,
        activeSecondaryLink,
        disablePrimaryNavCollapse,
        disablePromoNavCollapse,
        emitDirectionEvents,
      },
    })
  );
};

export const getDesktopNavHeight = (disableSecondary: boolean, isGuest: boolean): number => {
  // Pre-auth nav is larger
  const heightWithSecondary = isGuest ? 172 : 140;
  const heightWithoutSecondary = isGuest ? 136 : 104;
  return disableSecondary ? heightWithoutSecondary : heightWithSecondary;
};

export const getNavHeight = (disableSecondary: boolean, isGuest: boolean): number => {
  if (isDesktop()) {
    return getDesktopNavHeight(disableSecondary, isGuest);
  }
  return 60;
};

export default {
  emitUpdateNavDataEvent,
  getNavHeight,
};
