import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getModule, getModuleGroup } from 'selectors/moduleGroupSelectors';
import fetchModuleGroup from '../../actions/ModuleGroupActions';

export const AdminModule = ({
  component: Component,
  module,
  loadModuleGroup,
  moduleGroup,
  tag,
  searchFn,
  ...rest
}) => {
  // Only load the module group if it isn't already in the store
  useEffect(() => {
    if (tag && !moduleGroup) loadModuleGroup(tag);
  }, [loadModuleGroup, moduleGroup, tag]);

  // Only render the component if a module is available
  return (module && <Component module={module} {...rest} />) || null;
};

AdminModule.propTypes = {
  module: PropTypes.shape({}),
  loadModuleGroup: PropTypes.func.isRequired,
  moduleGroup: PropTypes.shape({}),
  tag: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  moduleGroup: getModuleGroup(state, ownProps),
  module: getModule(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
  loadModuleGroup: tag => dispatch(fetchModuleGroup(tag)),
});

const ConnectedAdminModule = connect(mapStateToProps, mapDispatchToProps)(AdminModule);

export const withAdminModule = (component, searchFn) => props => {
  return <ConnectedAdminModule component={component} searchFn={searchFn} {...props} />;
};
