import { connect } from 'react-redux';

import { getUserContext as getUserContextSelector } from 'selectors/user/userSelectors';
import { getNav } from 'actions/NavActions';
import { getUserContext, UserContext } from 'actions/UserActions';

import UnifiedNav from './UnifiedNav';
import { AppDispatch, RootState } from '../../../../reducers';

export type UnifiedNavStateProps = {
  userContext: UserContext;
};

const mapStateToProps = (state: RootState): UnifiedNavStateProps => ({
  userContext: getUserContextSelector(state),
});

export type UnifiedNavDispatchProps = {
  fetchUserContext: () => unknown;
  onGetNav: () => Promise<string>;
};

const mapDispatchToProps = (dispatch: AppDispatch): UnifiedNavDispatchProps => ({
  fetchUserContext: (): unknown => dispatch(getUserContext()),
  onGetNav: (): Promise<string> => dispatch(getNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnifiedNav);
