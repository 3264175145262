import React, { useState, useEffect, useMemo } from 'react';
import Sticky from 'react-stickynode';
import _throttle from 'lodash/throttle';
import cx from 'classnames';
import {
  SecondaryNavIdentifier,
  SecondaryNavItem,
  PrimaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';

import { NAV_LOADED } from 'constants/navConstants';

import { PLP_MODULE_GROUP_TAGS } from 'cards/constants/CardTypes';
import SkinnyBanner from 'components/promotions/SkinnyBanner';
import FeatureFlags from 'util/featureFlags';

import SecondaryNavDataV2 from 'components/common/header/SecondaryNavV2/SecondaryNavDataV2';
import { emitUpdateNavDataEvent, getNavHeight } from 'components/common/header/helpers';
import UnifiedNavHtmlLoader from './UnifiedNavHtmlLoader';

import { UnifiedNavDispatchProps, UnifiedNavStateProps } from '.';
import styles from './styles.module.less';

enum SecondaryType {
  SECONDARY_NAV_WEBSITE = 'website',
  SECONDARY_NAV_INVITES = 'invites',
  SECONDARY_NAV_GUESTLIST = 'guestlist',
  SECONDARY_NAV_ALBUMS = 'albums',
}

type UnifiedNavProps = UnifiedNavStateProps &
  UnifiedNavDispatchProps & {
    className?: string;
    showPromo?: boolean;
    activePrimaryLink?: PrimaryNavIdentifier;
    activeSecondaryLink?: SecondaryNavIdentifier;
    disablePrimaryNavCollapse?: boolean;
    disablePromoNavCollapse?: boolean;
    enableSticky?: boolean;
    activeCardEvent?: keyof typeof PLP_MODULE_GROUP_TAGS;
    albumEvent?: string;
    isHidden?: boolean;
    navItems?: SecondaryNavItem[];
    secondaryType?: SecondaryType | string;
    emitDirectionEvents?: boolean;
    unstickBanner?: boolean;
    isDigital?: boolean;
  };

const UnifiedNav: React.FC<UnifiedNavProps> = ({
  className,
  onGetNav,
  fetchUserContext,
  showPromo,
  activePrimaryLink,
  activeSecondaryLink,
  disablePrimaryNavCollapse,
  disablePromoNavCollapse,
  enableSticky = true,
  activeCardEvent,
  isHidden,
  userContext,
  navItems,
  secondaryType,
  emitDirectionEvents,
  unstickBanner,
  isDigital,
}) => {
  const [height, setHeight] = useState<number>();
  const [nav, setNav] = useState<string>('');

  // useMemo so that the event listener hook uses correct ref equality
  const secondaryData = useMemo(() => {
    let data;
    if (navItems) {
      data = navItems;
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_INVITES) {
      data = new SecondaryNavDataV2(userContext).getInvitesItems();
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_GUESTLIST) {
      data = new SecondaryNavDataV2(userContext).getGuestListItems();
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_WEBSITE) {
      data = new SecondaryNavDataV2(userContext).getWebsiteItems();
    } else if (secondaryType === SecondaryType.SECONDARY_NAV_ALBUMS) {
      data = new SecondaryNavDataV2(userContext).getPhotobooksItems();
    }
    return data;
  }, [navItems, userContext, secondaryType]);
  const disableSecondary = !secondaryData;

  const refreshNavHeight = _throttle(() => {
    const newHeight = getNavHeight(
      disableSecondary,
      userContext ? Boolean(userContext.is_guest) : true
    );
    if (height !== newHeight) {
      setHeight(newHeight);
    }
  }, 200);

  useEffect(() => {
    const handleInitNavData = (): void => {
      emitUpdateNavDataEvent({
        userContext,
        secondaryData,
        activePrimaryLink,
        activeSecondaryLink,
        disablePrimaryNavCollapse,
        disablePromoNavCollapse,
        emitDirectionEvents,
      });
    };
    if (!nav && onGetNav) {
      onGetNav()
        .then(res => {
          setNav(res);
          refreshNavHeight();
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }

    window.addEventListener(NAV_LOADED, handleInitNavData);
    window.addEventListener('resize', refreshNavHeight);

    return (): void => {
      window.removeEventListener(NAV_LOADED, handleInitNavData);
      window.removeEventListener('resize', refreshNavHeight);
    };
  }, [
    secondaryData,
    userContext,
    activeSecondaryLink,
    nav,
    onGetNav,
    refreshNavHeight,
    activePrimaryLink,
    disablePrimaryNavCollapse,
    disablePromoNavCollapse,
    showPromo,
    emitDirectionEvents,
  ]);

  // If userContext changes after the nav initialization, update the nav
  useEffect(() => {
    if (!userContext) {
      fetchUserContext();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      emitUpdateNavDataEvent({ userContext } as any);
    }
  }, [userContext, fetchUserContext]);

  // If secondaryData changes after the nav initialization, update the nav
  useEffect(() => {
    if (!secondaryData) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitUpdateNavDataEvent({ secondaryData } as any);
  }, [secondaryData]);

  useEffect(() => {
    setHeight(getNavHeight(disableSecondary, Boolean(userContext.is_guest)));
  }, [disableSecondary, userContext]);

  const defaultStyles: React.CSSProperties = {
    height,
    transform: 'none',
  };
  const hiddenStyles: React.CSSProperties = isHidden
    ? { position: 'absolute', left: '-99999px' }
    : {};

  return (
    <>
      <div
        className={cx('unified-nav', className, {
          [styles.unifiedNav]:
            FeatureFlags.get('dstdWebsiteEntry') &&
            secondaryType === SecondaryType.SECONDARY_NAV_INVITES,
        })}
        style={{ ...defaultStyles, ...hiddenStyles }}
      >
        <Sticky innerZ={500} enableTransforms={false} enabled={enableSticky}>
          <UnifiedNavHtmlLoader html={nav} />
          {activeCardEvent && !unstickBanner && !isDigital && (
            <SkinnyBanner tag={PLP_MODULE_GROUP_TAGS[activeCardEvent]} />
          )}
        </Sticky>
      </div>
      {activeCardEvent && unstickBanner && !isDigital && (
        <div className={styles.bannerWrapper}>
          <SkinnyBanner tag={PLP_MODULE_GROUP_TAGS[activeCardEvent]} />
        </div>
      )}
    </>
  );
};

export default UnifiedNav;
