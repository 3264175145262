import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

// LogRocket Keys
export const LOGROCKET_PLANNING_ONBOARD_FLAG = 'logrocket-planning-onboard';
export const LOGROCKET_WEBSITE_ONBOARD = 'logrocket-website-onboard';
export const SUPER_LINK_LOG_ROCKET = 'magic-link-log-rocket'; // CE-847 https://newamsterdamlabs.atlassian.net/browse/CE-847
export const LOGROCKET_INVITES_PDP = 'logrocket-invites-pdp'; // COMMERCE-585 https://newamsterdamlabs.atlassian.net/browse/COMMERCE-585
export const LOGROCKET_YOUR_WEDDING_V2 = 'logrocket-your-wedding-v2';

// Experiment Keys
export const CARDS_MOBILE_FILTERS = 'cards-mobile-filters';
export const REGISTRY_GIFT_GIVER_TILE_HEIGHT = 'registry-gift-giver-tile-height'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-929
export const REGISTRY_GIFT_GIVER_PDP_BADGE = 'registry-gift-giver-pdp-badge'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-931
export const HOME_NEWNESS = 'home-newness';
export const DESKTOP_TO_APP_ENABLED = 'desktop-to-app-enabled'; // https://newamsterdamlabs.atlassian.net/browse/CE-1417
export const POST_ONBOARDING_RW = 'post-onboarding-rw';
export const DSTD_WEDDING_WEBSITE_ENTRYPOINT = 'dstd-wedding-website-entrypoint-ii'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1133
export const HDYHAU_SUBWAY = 'hdyhau-subway';
export const DSTD_NAV_ENTRY = 'dstd-navigation-entry';
export const WEBSITE_TO_REGISTRY_V2 = 'website-to-registry-v2'; // https://newamsterdamlabs.atlassian.net/browse/CE-1848
export const CHAR_COUNT_INCREASE = 'website-char-count-increase'; // https://newamsterdamlabs.atlassian.net/browse/CE-1876
export const INVITES_PROMO_HIDE = 'invites-promo-hide'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1629
export const RW_PLANNING_ONBOARDING = 'real-wedding-style-finder-general-planning-onboarding-flow'; // https://newamsterdamlabs.atlassian.net/browse/INSPO-7015
export const RECAPTCHA_REFACTOR = 'recaptcha-refactor'; // https://newamsterdamlabs.atlassian.net/browse/CE-2186
export const PAPER_PLP_PRICE_DISPLAY = 'paper-plp-price-display'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1941
export const WEBSITE_V2 = 'website-v2';
export const YW_DASHBOARD_V2 = 'yw-dashboard-v2';
export const PAPER_UNIVERSAL_LANDING_PAGE_REDESIGN = 'paper-universal-landing-page-redesign-v3'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2411
export const PAPER_GUEST_ADDRESSING_IMPROVEMENTS = 'paper-guest-addressing-improvements'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2477
export const ONBOARDING_GENERAL_PLANNING_20221229 = 'onboarding-general-planning-20221229'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2676
export const ONBOARDING_PAPER_QUICK_20221229 = 'onboarding-paper-quick-20221229'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2676
export const YW_DASHBOARD_V2_NEW_USERS = 'yw-dashboard-v2-new-users';
export const YW_DASHBOARD_V2_PAPER_CTA = 'yw-dashboard-v2-paper-cta'; // https://newamsterdamlabs.atlassian.net/browse/CE-2745
export const YW_DASHBOARD_V2_REGISTRY_CTA = 'yw-dashboard-v2-registry-cta'; // https://newamsterdamlabs.atlassian.net/browse/CE-2744

// Season Flags
export const HOLIDAYS_2022 = 'holidays2022';

// Promo flags
export const INVITES_PROMO_PAPER40 = 'invites-promo-paper40';
export const ALBUMS_PROMO_ALBUMS25 = 'albums25';
export const STD_PROMO_50FREE = 'std-promo-50free';
export const HOLIDAY_PROMO_HOLIDAY50 = 'holiday-promo-holiday50';

const FALLBACK_VARIATIONS = {
  // LogRocket Flags
  [LOGROCKET_PLANNING_ONBOARD_FLAG]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_WEBSITE_ONBOARD]: experimentFlagsHelper.createExperimentFlags(),
  [SUPER_LINK_LOG_ROCKET]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_INVITES_PDP]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_YOUR_WEDDING_V2]: experimentFlagsHelper.createExperimentFlags(),
  // Experiment Flags
  [HOME_NEWNESS]: experimentFlagsHelper.createExperimentFlags(),
  [REGISTRY_GIFT_GIVER_TILE_HEIGHT]: experimentFlagsHelper.createExperimentFlags(),
  [REGISTRY_GIFT_GIVER_PDP_BADGE]: experimentFlagsHelper.createExperimentFlags(),
  [CARDS_MOBILE_FILTERS]: experimentFlagsHelper.createExperimentFlags(),
  [DESKTOP_TO_APP_ENABLED]: experimentFlagsHelper.createExperimentFlags(),
  [POST_ONBOARDING_RW]: experimentFlagsHelper.createExperimentFlags(),
  [DSTD_WEDDING_WEBSITE_ENTRYPOINT]: experimentFlagsHelper.createExperimentFlags(),
  [HDYHAU_SUBWAY]: experimentFlagsHelper.createExperimentFlags(),
  [DSTD_NAV_ENTRY]: experimentFlagsHelper.createExperimentFlags(),
  [INVITES_PROMO_PAPER40]: experimentFlagsHelper.createExperimentFlags(),
  [ALBUMS_PROMO_ALBUMS25]: experimentFlagsHelper.createExperimentFlags(),
  [STD_PROMO_50FREE]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_PROMO_HOLIDAY50]: experimentFlagsHelper.createExperimentFlags(),
  [INVITES_PROMO_HIDE]: experimentFlagsHelper.createExperimentFlags(),
  [RW_PLANNING_ONBOARDING]: experimentFlagsHelper.createExperimentFlags(),
  [RECAPTCHA_REFACTOR]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_PLP_PRICE_DISPLAY]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAYS_2022]: experimentFlagsHelper.createExperimentFlags(),
  [WEBSITE_V2]: experimentsFlagsHelperSSR.createExperimentFlags(),
  [YW_DASHBOARD_V2]: experimentsFlagsHelperSSR.createExperimentFlags(),
  [PAPER_UNIVERSAL_LANDING_PAGE_REDESIGN]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_GUEST_ADDRESSING_IMPROVEMENTS]: experimentFlagsHelper.createExperimentFlags(),
  [ONBOARDING_GENERAL_PLANNING_20221229]: experimentFlagsHelper.createExperimentFlags(),
  [ONBOARDING_PAPER_QUICK_20221229]: experimentFlagsHelper.createExperimentFlags(),
  [YW_DASHBOARD_V2_NEW_USERS]: experimentFlagsHelper.createExperimentFlags(),
  [YW_DASHBOARD_V2_PAPER_CTA]: experimentFlagsHelper.createExperimentFlags(),
  [YW_DASHBOARD_V2_REGISTRY_CTA]: experimentFlagsHelper.createExperimentFlags(),
};

export default experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);

const FALLBACK_VARIATIONS_SSR = {
  [WEBSITE_V2]: experimentsFlagsHelperSSR.createExperimentFlags(),
  [YW_DASHBOARD_V2]: experimentsFlagsHelperSSR.createExperimentFlags(),
  [PAPER_UNIVERSAL_LANDING_PAGE_REDESIGN]: experimentsFlagsHelperSSR.createExperimentFlags(),
  [YW_DASHBOARD_V2_NEW_USERS]: experimentsFlagsHelperSSR.createExperimentFlags(),
};

export const experimentFlagsSSR = experimentsFlagsHelperSSR.makeExperimentFlags(
  FALLBACK_VARIATIONS_SSR
);

// Help function to check if variantion is in experiment
export const isInExperiment = (variant: string): boolean =>
  variant !== 'TEST-0' && variant !== 'NOT_IN_TEST' && /^TEST-[1-9]$/.test(variant);
