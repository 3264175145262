import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import cx from 'classnames';
import { withAdminModule } from 'helpers/hoc/withAdminModule';

import styles from './styles.module.less';

export const SkinnyBannerView = ({ module }) => {
  const transform = node =>
    node.type === 'tag' && node.name !== 'strong' && node.name !== 'b' ? null : '';
  const content = ReactHtmlParser(module.link_label, { decodeEntities: true, transform });
  return (
    <div className={cx(styles.bannerPromoModule, styles.bannerPromoModuleIsRedesign)}>
      <a href={module.link_path} rel="noopener noreferrer">
        <span>{content}</span>
      </a>
    </div>
  );
};

const isSkinnyBannerModule = module => module.title && module.title.startsWith('SkinnyBanner -');

export default withAdminModule(SkinnyBannerView, isSkinnyBannerModule);
