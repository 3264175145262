import { createSelector } from 'reselect';
import _get from 'lodash/get';

export const getModuleGroup = (state, props) =>
  state.moduleGroup ? state.moduleGroup[props.tag] : null;
const getModuleSearchFn = (state, props) => props.searchFn;

export const getModuleGroupViews = (state, moduleGroupName) =>
  _get(state, ['moduleGroup', moduleGroupName, 'moduleViews'], []);

/*
 * Returns a module that is part of a existing module group.
 *
 * Required props:
 *   - tag: The parent module group's tag
 *   - searchFn: Called for each module in the group until one returns true
 */
export const getModule = createSelector(
  getModuleGroup,
  getModuleSearchFn,
  (moduleGroup, searchFn) =>
    (searchFn &&
      moduleGroup &&
      moduleGroup.moduleViews &&
      moduleGroup.moduleViews.find(module => searchFn(module))) ||
    null
);

export const getModules = createSelector(
  getModuleGroup,
  getModuleSearchFn,
  (moduleGroup, searchFn) =>
    (searchFn &&
      moduleGroup &&
      moduleGroup.moduleViews &&
      moduleGroup.moduleViews.filter(module => searchFn(module))) ||
    null
);
