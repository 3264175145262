/**
 * Created by zoechodosh on 5/12/17.
 */
import fetch from 'isomorphic-fetch';
import * as ActionTypes from './types/ModuleGroupActionTypes';

function handleErrors(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
}

export function requestModuleGroup() {
  return {
    type: ActionTypes.REQUEST_MODULE_GROUP,
  };
}

export function receiveModuleGroup(data) {
  return {
    type: ActionTypes.RECEIVE_MODULE_GROUP,
    payload: data,
  };
}

const requestsInProgress = {};

// Only one request per module group can be in progress at once.
export default function fetchModuleGroup(tag) {
  return dispatch => {
    dispatch(requestModuleGroup());
    if (!requestsInProgress[tag]) {
      requestsInProgress[tag] = fetch(`/web-api/v1/module-group/tag/${tag}`, {
        credentials: 'same-origin',
      })
        .then(handleErrors)
        .then(response => response.json())
        .then(json => dispatch(receiveModuleGroup(json)))
        .then(() => delete requestsInProgress[tag])
        .catch(() => delete requestsInProgress[tag]);
    }

    return requestsInProgress[tag];
  };
}
