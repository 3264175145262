import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import { UserContext } from 'actions/UserActions';
import { PHOTOBOOKS_SECONDARY } from 'constants/navConstants';
import gift from 'assets/images/invitations/gift.svg';
import experimentFlags, { HOLIDAYS_2022 } from '../../../../util/experimentFlags';

class SecondaryNavDataV2 {
  userContext: UserContext;

  constructor(userContext = {}) {
    this.userContext = userContext;
  }

  // Invites - Pre/post auth
  getInvitesItems(): SecondaryNavItem[] {
    const is2022Holidays = experimentFlags.isUnderTest(HOLIDAYS_2022, this.userContext, false);

    const saveTheDate = {
      path: '/wedding-planning/save-the-date/shop',
      title: 'Save the dates',
      id: SecondaryNavIdentifier.SAVE_THE_DATE,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const matchingSuites = {
      path: '/wedding-planning/suites/shop',
      title: 'Matching suites',
      id: SecondaryNavIdentifier.MATCHING_SUITES,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const digitalSaveTheDate = {
      path: '/wedding-planning/digital/save-the-date/shop',
      title: 'Digital save the dates',
      id: SecondaryNavIdentifier.DIGITAL_SAVE_THE_DATE,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const invitations = {
      path: '/wedding-planning/invitations/shop',
      title: 'Invitations',
      id: SecondaryNavIdentifier.INVITATION,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const enclosureCards = {
      path: '/wedding-planning/enclosures/shop',
      title: 'Enclosure cards',
      id: SecondaryNavIdentifier.DETAILS,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const menus = {
      path: '/wedding-planning/menus/shop',
      title: 'Menus',
      id: SecondaryNavIdentifier.MENU,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const programs = {
      path: '/wedding-planning/programs/shop',
      title: 'Programs',
      id: SecondaryNavIdentifier.PROGRAM,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const placeCards = {
      path: '/wedding-planning/place-cards/shop',
      title: 'Place cards',
      id: SecondaryNavIdentifier.PLACE,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const thankYouCards = {
      path: '/wedding-planning/thank-you-cards/shop',
      title: 'Thank you cards',
      id: SecondaryNavIdentifier.THANK_YOU,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const holidayCards = {
      path: '/wedding-planning/holiday-cards/shop',
      title: 'Holiday',
      id: SecondaryNavIdentifier.HOLIDAY,
      parent: PrimaryNavIdentifier.PAPER,
      icon: gift,
    };
    const changeTheDate = {
      path: '/wedding-planning/change-the-date/shop',
      title: 'Change the dates',
      id: SecondaryNavIdentifier.CHANGE_THE_DATE,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const disney = {
      path: '/wedding-planning/disney',
      title: 'Disney',
      id: SecondaryNavIdentifier.DISNEY,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const albums = {
      title: 'Albums',
      path: '/wedding-albums/shop',
      id: PHOTOBOOKS_SECONDARY.SHOP,
      parent: PrimaryNavIdentifier.PAPER,
    };
    const matchingSuitesFeaturedArtists = {
      path: '/wedding-planning/suites/shop?featuredArtists=featuredArtist',
      title: 'Our featured artists',
    };

    const items: SecondaryNavItem[] = [];

    const matchingCollectionsChildren = [matchingSuites, matchingSuitesFeaturedArtists, disney];

    const matchingCollectionsParent = {
      ...matchingSuites,
      title: 'Collections',
      children: matchingCollectionsChildren,
      showCaret: true,
    };

    const saveTheDatesParent = {
      ...saveTheDate,
      children: [saveTheDate, digitalSaveTheDate, changeTheDate],
      showCaret: true,
    };

    const invitationsParent = {
      ...invitations,
      children: [
        { ...invitations, title: 'Wedding invitations' },
        { ...enclosureCards, title: 'Enclosures' },
      ],
      showCaret: true,
    };

    const dayOfParent = {
      path: '/wedding-planning/day-of',
      title: 'Day-of',
      id: SecondaryNavIdentifier.DAY_OF,
      parent: PrimaryNavIdentifier.PAPER,
      children: [placeCards, menus, programs],
      showCaret: true,
    };

    items.push(
      matchingCollectionsParent,
      saveTheDatesParent,
      invitationsParent,
      dayOfParent,
      {
        ...thankYouCards,
        title: 'Thank yous',
      },
      albums
    );

    if (is2022Holidays) {
      items.push(holidayCards);
    }

    if (this.userContext.has_invitation_account) {
      const title = 'Your invites and paper';
      const yourInvitesPaperDropdown = {
        showCaret: true,
        children: [
          {
            path: '/wedding-planning/projects/drafts',
            title: 'Your drafts',
          },
          {
            path: '/wedding-planning/projects/finished',
            title: 'Your orders',
          },
          {
            path: '/favorites/paper',
            title: 'Your favorites',
          },
        ],
      };

      items.unshift({
        title,
        path: '/wedding-planning/projects/drafts',
        id: SecondaryNavIdentifier.PROJECTS,
        parent: PrimaryNavIdentifier.PAPER,
        ...yourInvitesPaperDropdown,
      });
    }

    return items;
  }

  // Photobooks
  getPhotobooksItems(): SecondaryNavItem[] {
    // TODO: update zola-helpers secondary nav constants
    const parent = PrimaryNavIdentifier.ALBUMS;

    const albumsOrdersAndDrafts = {
      title: 'Your Orders + Drafts',
      path: '/wedding-albums/drafts',
      id: PHOTOBOOKS_SECONDARY.DRAFTS,
      parent,
    };

    const shopAlbums = {
      title: 'Shop Albums',
      path: '/wedding-albums/shop',
      id: PHOTOBOOKS_SECONDARY.SHOP,
      parent,
    };

    const classicAlbums = {
      path: '/wedding-albums/select?album_type=classic',
      title: 'Classic Albums',
      id: PHOTOBOOKS_SECONDARY.CLASSIC,
      parent,
    };

    const premierAlbums = {
      path: '/wedding-albums/select?album_type=premier',
      title: 'Premier Albums',
      id: PHOTOBOOKS_SECONDARY.PREMIER,
      parent,
    };

    const deluxeAlbums = {
      path: '/wedding-albums/select?album_type=deluxe',
      title: 'Deluxe Albums',
      id: PHOTOBOOKS_SECONDARY.DELUXE,
      parent,
    };

    const showOrdersAndDrafts =
      this.userContext.has_invitation_account &&
      this.userContext.invitation_account &&
      this.userContext.invitation_account.photobooks_onboarding_completed;

    const items: SecondaryNavItem[] = [
      ...(showOrdersAndDrafts ? [albumsOrdersAndDrafts] : []),
      shopAlbums,
      classicAlbums,
      premierAlbums,
      deluxeAlbums,
    ];

    return items;
  }

  // Guest List - Post auth
  // could be static
  getGuestListItems(): SecondaryNavItem[] {
    const events = {
      path: '/wedding/manage/events',
      title: 'Your Events',
      id: SecondaryNavIdentifier.EVENTS,
      parent: PrimaryNavIdentifier.GUEST_LIST,
    };
    const superLink = {
      path: '/wedding/manage/guests/collection-link',
      title: 'Your Contact Collector',
      id: SecondaryNavIdentifier.SUPER_LINK,
      parent: PrimaryNavIdentifier.GUEST_LIST,
    };
    const seatingChart = {
      title: 'Your Seating Chart',
      path: '/wedding/manage/seating',
      id: SecondaryNavIdentifier.SEATING,
      parent: PrimaryNavIdentifier.GUEST_LIST,
    };
    const hotelBlocks = {
      title: 'Hotel Blocks',
      path: 'https://zola.hotelplanner.com/Group-Rate/?kw=guestnav',
      parent: PrimaryNavIdentifier.GUEST_LIST,
    };

    const guestListWithSubitems: SecondaryNavItem = {
      path: '/wedding/manage/guests/all',
      title: 'Your Guest List',
      id: SecondaryNavIdentifier.ALL_GUESTS,
      parent: PrimaryNavIdentifier.GUEST_LIST,
      showCaret: true,
      children: [
        {
          path: '/wedding/manage/guests/all',
          title: 'Manage Guest List',
        },
        {
          path: '/wedding/manage/guests/build',
          title: 'Invite Guests to Events',
        },
        {
          path: '/wedding/manage/guests/envelopes',
          title: 'Address Envelopes',
        },
        {
          path: '/wedding/manage/guests/rsvps/overview',
          title: 'Track RSVPs',
        },
      ],
    };

    return [guestListWithSubitems, superLink, events, seatingChart, hotelBlocks];
  }

  // Website - Post auth
  // Could be static
  getWebsiteItems(): SecondaryNavItem[] {
    const manageWebsite = {
      id: SecondaryNavIdentifier.MANAGE,
      path: '/wedding/manage/website/manage',
      title: 'Your Website',
      parent: PrimaryNavIdentifier.WEBSITE,
    };

    const editWebsite = {
      id: SecondaryNavIdentifier.EDIT,
      path: '/wedding/manage/website/pages/home',
      title: 'Edit Content',
      parent: PrimaryNavIdentifier.WEBSITE,
    };

    const viewDesigns = {
      id: SecondaryNavIdentifier.DESIGNS,
      path: '/wedding/manage/website/designs',
      title: 'Explore Designs',
      parent: PrimaryNavIdentifier.WEBSITE,
    };

    const customizeDesign = {
      id: SecondaryNavIdentifier.CUSTOMIZE,
      path: '/wedding/manage/website/customize',
      title: 'Customize Design',
      parent: PrimaryNavIdentifier.WEBSITE,
    };

    return [manageWebsite, editWebsite, customizeDesign, viewDesigns];
  }
}

export default SecondaryNavDataV2;
