import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

export { NAV_DATA };
export const NAV_LOADED = 'NAV_LOADED';
export const INIT_NAV_DATA = 'INIT_NAV_DATA';
export const WEB_NAV_ID = 'root-nav';
export const PHOTOBOOKS_SECONDARY = {
  DRAFTS: 'DRAFTS',
  SHOP: 'SHOP',
  CLASSIC: 'CLASSIC',
  PREMIER: 'PREMIER',
  DELUXE: 'DELUXE',
};
